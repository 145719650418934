.react-tel-input {
  margin-bottom: 10px;
  margin-top: 10px;
}

.react-tel-input .special-label {
  background: var(--themec);
}

.react-tel-input .form-control {
  color: var(--contrast);
  background: var(--themec);
  width: 100%;
}

.react-tel-input .country-list {
  background: var(--themec);
}

.react-tel-input .country-list .country.highlight {
  background: var(--main);
}

.react-tel-input .country-list .country .dial-code {
  color: var(--contrast);
}

.react-tel-input .country-list .country:hover {
  background: var(--main);
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid var(--main);
}

.react-tel-input .form-control:focus {
  border-color: var(--maincolor);
  box-shadow: 0 0 0 1px var(--maincolor);
}
